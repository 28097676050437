import React from "react";

const DetalheCte = ({ entity }) => {
    let tributacao = [];
    tributacao["00"] = "Tributada normal";
    tributacao["10"] = "Tributada e com cobrança do ICMS por substituição tributária";
    tributacao["20"] = "Com redução de base de cálculo";
    tributacao["30"] = "Isenta ou não tributada e com cobrança do ICMS por substituição tributária";
    tributacao["40"] = "Isenta";
    tributacao["41"] = "Não tributada";
    tributacao["50"] = "Suspensão";
    tributacao["51"] = "Diferimento";
    tributacao["60"] = "ICMS cobrado anteriormente por substituição tributária";
    tributacao["70"] = "Com redução de base de cálculo e cobrança do ICMS por substituição tributária";
    tributacao["90"] = "Outras";

    function formactValue(value) {
        if (!isNaN(value) && value !== "") {
            return parseFloat(value)
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+,)/g, "$1.");
        }

        return value;
    }

    return (
        <table className="table-fixed ml-16 mt-4 mb-4" style={{ minWidth: "80vw" }}>
            <tbody className="border-2">
                <tr>
                    <td colSpan="2" className="border-b-2">
                        <div className="flex ml-2 mt-2 mb-2">
                            <div className="w-32">NOTA FISCAL</div>
                            <div className="flex-auto ">{entity?.nfe}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="border-b-2 border-r-2">
                        <div className="flex ml-2 mt-2">
                            <div className="w-32">REMETENTE</div>
                            <div className="flex-auto pr-2">{entity?.remetente}</div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">ENDEREÇO</div>
                            <div className="flex-auto pr-2">
                                {entity?.remetenteEndereço} {entity?.remetenteBairro}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">MUNICÍPIO</div>
                            <div className="flex-1">
                                {entity?.remetenteCidade} - {entity?.remetenteEstado}
                            </div>
                            <div className="flex-1 pr-2">
                                <span className="mr-6">CEP</span>
                                {entity?.remetenteCep}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">CNPJ/CPF</div>
                            <div className="flex-1">{entity?.remetenteCnpjCpf}</div>
                            <div className="flex-1 pr-2">
                                <span className="mr-10">IE</span>
                                {entity?.remetenteInscricaoEstadual}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">PAÍS</div>
                            <div className="flex-1">{entity?.remetentePais}</div>
                            <div className="flex-1 mb-3 pr-2">
                                <span className="mr-4">FONE</span>
                                {entity?.remetenteFone}
                            </div>
                        </div>
                    </td>
                    <td className="border-b-2 border-r-2">
                        <div className="flex ml-2 mt-2">
                            <div className="w-32">DESTINATÁRIO</div>
                            <div className="flex-auto pr-2">{entity?.destinatario}</div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">ENDEREÇO</div>
                            <div className="flex-auto pr-2">
                                {entity?.destinatarioEndereco} {entity?.destinatarioBairro}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">MUNICÍPIO</div>
                            <div className="flex-1">
                                {entity?.destinatarioMunicipio} - {entity?.destinatarioEstado}
                            </div>
                            <div className="flex-1 pr-2">
                                <span className="mr-6">CEP</span>
                                {entity?.destinatarioCep}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">CNPJ/CPF</div>
                            <div className="flex-1">{entity?.destinatarioCnpjCpf}</div>
                            <div className="flex-1 pr-2">
                                <span className="mr-10">IE</span>
                                {entity?.destinatarioInscricaoEstadual}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">PAÍS</div>
                            <div className="flex-1">{entity?.destinatarioPais}</div>
                            <div className="flex-1 mb-3 pr-2">
                                <span className="mr-4">FONE</span>
                                {entity?.destinatarioFone}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td className="border-b-2 border-r-2">
                        <div className="flex ml-2 mt-2">
                            <div className="w-32">EXPEDIDOR</div>
                            <div className="flex-auto pr-2">{entity?.expedidor}</div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">ENDEREÇO</div>
                            <div className="flex-auto pr-2">
                                {entity?.expedidorEndereco} {entity?.expedidorBairro}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">MUNICÍPIO</div>
                            <div className="flex-1">
                                {entity?.expedidorMunicipio} - {entity?.expedidorEstado}
                            </div>
                            <div className="flex-1 pr-2">
                                <span className="mr-6">CEP</span>
                                {entity?.expedidorCep}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">CNPJ/CPF</div>
                            <div className="flex-1">{entity?.expedidorCnpjCpf}</div>
                            <div className="flex-1 pr-2">
                                <span className="mr-10">IE</span>
                                {entity?.expedidorInscricaoEstadual}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">PAÍS</div>
                            <div className="flex-1">{entity?.expedidorPais}</div>
                            <div className="flex-1 mb-3 pr-2">
                                <span className="mr-4">FONE</span>
                                {entity?.expedidorFone}
                            </div>
                        </div>
                    </td>
                    <td className="border-b-2 border-r-2">
                        <div className="flex ml-2">
                            <div className="w-32">RECEBEDOR</div>
                            <div className="flex-auto pr-2">{entity?.recebedor}</div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">ENDEREÇO</div>
                            <div className="flex-auto pr-2">
                                {entity?.recebedorEndereco} {entity?.recebedorBairro}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">MUNICÍPIO</div>
                            <div className="flex-1">
                                {entity?.recebedorMunicipio} - {entity?.recebedorEstado}
                            </div>
                            <div className="flex-1 pr-2">
                                <span className="mr-6">CEP</span>
                                {entity?.recebedorCep}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">CNPJ/CPF</div>
                            <div className="flex-1">{entity?.recebedorCnpjCpf}</div>
                            <div className="flex-1 pr-2">
                                <span className="mr-10">IE</span>
                                {entity?.recebedorInscricaoEstadual}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-32">PAÍS</div>
                            <div className="flex-1">{entity?.recebedorPais}</div>
                            <div className="flex-1 mb-3 pr-2">
                                <span className="mr-4">FONE</span>
                                {entity?.recebedorFone}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className=" border-b-2 ">
                        <div className="flex ml-2 mt-2">
                            <div className="mr-4">TOMADOR DO SERVIÇO</div>
                            <div className="flex-auto ">{entity?.tomador}</div>
                            <div className="flex-auto ">
                                <span className="mr-2">MUNICÍPIO</span> {entity?.tomadorMunicipio}
                            </div>
                            <div className="flex-auto ">
                                <span className="mr-2">UF</span> {entity?.tomadorEstado}
                            </div>
                            <div className="flex-auto ">
                                <span className="mr-2">CEP</span> {entity?.tomadorCep}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-24 ">ENDEREÇO</div>
                            <div className="flex-auto ">
                                {entity?.tomadorEndereco} BAIRRO {entity?.tomadorBairro}
                            </div>
                            <div className="flex-auto ">
                                <span className="mr-2">PAÍS</span> {entity?.tomadorPais}
                            </div>
                        </div>
                        <div className="flex ml-2">
                            <div className="w-24 ">CNPJ/CPF</div>
                            <div className="flex-auto ">{entity?.tomadorCnpjCpf}</div>
                            <div className="flex-auto ">
                                <span className="mr-2">IE</span>
                                {entity?.tomadorInscricaoEstadual}
                            </div>
                            <div className="flex-auto  mb-3">
                                <span className="mr-2">FONE</span>
                                {entity?.tomadorFone}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className="border-b-2 ">
                        <div className="flex">
                            <div className="flex-1 border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">PRODUTO PREDOMINANTE</div>
                                <div className="">{entity?.produtoPredominante}</div>
                            </div>
                            <div className="flex-1 border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">OUTRAS CARACTERÍSTICAS DA CARGA</div>
                                <div className="">{entity?.caracteristicaCarga}</div>
                            </div>
                            <div className="flex-1 pl-2 pt-2 pr-2 pb-2">
                                <div className="">VALOR TOTAL DA MERCADORIA</div>
                                <div className=" text-right">{formactValue(entity?.totalMercadoria)}</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className="border-b-2 ">
                        <div className="flex">
                            <div className="border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">QTD</div>
                                <div className="">CARGA</div>
                            </div>
                            <div className="flex-1 border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">PESO BRUTO</div>
                                <div className=" text-right">{formactValue(entity?.pesoBruto)} KG</div>
                            </div>
                            <div className="flex-1 border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">{entity?.tpMed}</div>
                                <div className=" text-right">{formactValue(entity?.palete)} UN</div>
                            </div>
                            <div className="flex-1">
                                <div className="flex-1 border-r-2 pl-2 pt-2 pr-2 pb-2">
                                    <div className="">PLACA</div>
                                    <div className="">{entity?.placas}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className="border-b-2  text-center pt-2 pb-2">
                        COMPONENTES DO VALOR DA PRESTAÇÃO DE SERVIÇO
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className="">
                        <div className="flex">
                            <div className="flex-1 flex border-r-2">
                                <div className="flex-1 border-b-2 pl-2 pt-2 pb-2">
                                    <div className="">NOME</div>
                                    <div className="">{entity?.frete ? "FRETE" : ""}</div>
                                    <div className="">{entity?.pedagio ? "PEDÁGIO" : ""}</div>
                                    <div className="">{entity?.icms ? "ICMS" : ""}</div>
                                </div>
                                <div className="flex-1 text-right border-b-2 pt-2 pr-2 pb-2">
                                    <div className=" ">VALOR</div>
                                    <div className="">{formactValue(entity?.frete)}</div>
                                    <div className="">{formactValue(entity?.pedagio)}</div>
                                    <div className="">{formactValue(entity?.icms)}</div>
                                </div>
                            </div>
                            <div className="flex-1 flex border-r-2">
                                <div className="flex-1 border-b-2 pl-2 pt-2 pb-2">
                                    <div className="">NOME</div>
                                    <div className="">{entity?.advalorem ? "ADVALOREM" : ""}</div>
                                    <div className="">{entity?.gris ? "GRIS" : ""}</div>
                                    <div className="">&nbsp;</div>
                                </div>
                                <div className="flex-1 text-right border-b-2 pt-2 pr-2 pb-2">
                                    <div className=" ">VALOR</div>
                                    <div className="">{formactValue(entity?.advalorem)}</div>
                                    <div className="">{formactValue(entity?.gris)}</div>
                                    <div className="">&nbsp;</div>
                                </div>
                            </div>
                            <div className="flex-1 flex border-r-2">
                                <div className="flex-1 border-b-2 pl-2 pt-2 pb-2">
                                    <div className="">NOME</div>
                                    <div className="">{entity?.pis ? "PIS" : ""}</div>
                                    <div className="">{entity?.cofins ? "COFINS" : ""}</div>
                                    <div className="">&nbsp;</div>
                                </div>
                                <div className="flex-1 text-right border-b-2 pt-2 pr-2 pb-2">
                                    <div className=" ">VALOR</div>
                                    <div className="">{formactValue(entity?.pis)}</div>
                                    <div className="">{formactValue(entity?.cofins)}</div>
                                    <div className="">&nbsp;</div>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className=" pl-2 pt-2">VALOR TOTAL DO SERVIÇO</div>
                                <div className=" text-right border-b-2 pr-2 pb-2">{formactValue(entity?.totalServico)}</div>
                                <div className=" pl-2 pt-2">VALOR A RECEBER</div>
                                <div className=" text-right border-b-2 pr-2 pb-2">{formactValue(entity?.valorReceber)}</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className="border-b-2 md:p-1  text-center">
                        INFORMAÇÕES RELATIVAS AO IMPOSTO
                    </td>
                </tr>
                <tr className="w-5/5">
                    <td colSpan="2" className="">
                        <div className="flex">
                            <div className="flex-auto border-r-2 pl-2 pt-2 pb-2">
                                <div className="">SITUAÇÃO TRIBUTÁRIA</div>
                                <div className="">
                                    {entity?.situacaoTributaria} - {tributacao[entity?.situacaoTributaria]}
                                </div>
                            </div>
                            <div className="flex-auto border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">BASE DE CÁLCULO</div>
                                <div className=" text-right">{entity?.baseCalculo}</div>
                            </div>
                            <div className="flex-auto border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">ALIQ ICMS</div>
                                <div className=" text-right">{formactValue(entity?.aliquotaIcms)}</div>
                            </div>
                            <div className="flex-auto border-r-2 pl-2 pt-2 pr-2 pb-2">
                                <div className="">VALOR ICMS</div>
                                <div className=" text-right">{formactValue(entity?.valorIcms)}</div>
                            </div>
                            <div className="w-24 border-r-2">
                                <div className="">&nbsp;</div>
                                <div className="">&nbsp;</div>
                            </div>
                            <div>
                                <div className="w-24 ">&nbsp;</div>
                                <div className="">&nbsp;</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default DetalheCte;
